import { Category } from '@/lib/strapi-types/Category';
import { Country } from '@/lib/strapi-types/Country';

import { BackEndpoints } from '@/lib/types/enums/backEndpoints.enum';

export async function useFindCountryCategories(countries: Country[]) {
  if (countries.length === 0) return { categories: [] };
  const { find } = useStrapi<Category['attributes']>();

  const countryIsoCodes = countries.map(country => country.attributes.iso);

  const filters = {
    categoryMdvAttribute: 'country',
    mdvDstMapsShortname: countryIsoCodes
  };

  const populate = {
    image: {
      fields: ['url']
    },
    country: { fields: ['slug'], populate: { geographicZones: { fields: ['name', 'slug'] } } }
  };

  const { data: categoriesData } = await useAsyncData(
    `${BackEndpoints.CATEGORIES}-${JSON.stringify(filters)}`,
    async () => {
      const categories = await find(BackEndpoints.CATEGORIES, {
        fields: ['urlPath', 'title', 'mdvDstMapsShortname'],
        populate,
        filters
      });

      return categories.data
        .map(category => ({
          destination: category,
          geographicZoneSlug:
            category.attributes.country?.data.attributes.geographicZones?.data[0]?.attributes.slug
        }))
        .sort((a: any, b: any) => {
          return (
            countryIsoCodes.indexOf(a.destination.attributes.mdvDstMapsShortname) -
            countryIsoCodes.indexOf(b.destination.attributes.mdvDstMapsShortname)
          );
        });
    }
  );

  return { categories: categoriesData.value };
}
